body {
  min-height: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #fff7eb;
}

.hover-dark-red:hover {
  border-bottom: 3px solid #e7040f;
  transition: border-bottom 0.1s;
}

.f-headline-ns {
  font-size: 15vmin;
}
.text_avail {
  color: #a5cf6f;
}
.f1-ns,
.f2-ns,
p.text {
  margin: 0 0 0.5rem 0;
}
.section-layout {
  display: flex;
  flex-direction: column;
}
.clients-list {
  max-width: 100%;
  columns: 2 auto;
}

div.clients-list > p {
  margin: 0 1rem 1rem 0;
  max-width: 100%;
}
.section-container {
  display: flex;
  flex-direction: column;
  width: 1470px;
  max-width: 1470px;
  padding-top: 156px;
  padding-bottom: 156px;
  justify-content: flex-start;
  align-items: flex-start;
}

.text-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: auto auto;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
}

.grid-container--content {
  width: 100%;
  justify-content: start;
  justify-items: start;
  align-items: start;
  align-content: start;
  grid-column-gap: 4rem;
  grid-row-gap: 0;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
}

.grid-block--title {
  display: flex;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

footer > .section-layout {
  height: 50vh;
}
.arrow {
  width: 5rem;
  height: 5rem;
}
@media (min-width: 992px) {
  b.mb4-ns {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 30rem) {
  .mb2-ns {
    margin-bottom: 0.8rem;
  }
}
@media (max-width: 796px) {
  .f-headline-ns {
    font-size: 10vmin;
  }
  .mb2-ns {
    margin-bottom: 0.4rem;
  }
}

@media (max-width: 991px) {
  .grid-container--content {
    grid-auto-columns: 0.25fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  b.mb4-ns {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 769px) {
  b.mb4-ns {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  b.mb4-ns {
    margin-bottom: 0;
  }
}

@media (max-width: 579px) {
  .flex-row-s {
    flex-direction: row;
  }
  b.mb4-ns {
    margin-bottom: 0;
  }
  .text-avail {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
  div.circle {
    width: 1.2rem;
    height: 1.2rem;
  }
  .f-headline-ns {
    font-size: 8vmin;
  }
  .mb2-ns {
    margin-bottom: 0;
  }
}

.mw55 {
  max-width: 16rem;
}
.card {
  padding-bottom: 3px;
}
article > .link:visited {
  color: currentColor;
}
a {
  color: inherit;
  outline: 0;
}
a.link {
  outline: 0;
}
a:visited {
  color: currentColor;
  outline: 0;
}

a.active {
  color: red;
  outline: 0;
}

.card:hover {
  border-bottom: 3px #e7040f solid;
  padding-bottom: 0px;
}
.card > h2:hover {
  color: #e7040f;
}

h1:hover {
  text-decoration: underline;
  text-decoration-color: #e7040f;
}

.red-hover:hover {
  text-decoration: underline;
  text-decoration-color: #e7040f;
}

@media screen and (min-width: 960px) {
  .mh7-l {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.photo {
  max-height: 700px;
  width: auto;
  vertical-align: middle;
}

.no-underline-hover {
  text-decoration: none !important;
}

.no-underline-hover:hover {
  text-decoration: none !important;
}
