.circle {
  text-align: center;
  color: #fff;
  padding: 5px;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transition: background-color 1s;
  background-color: #999;
  animation: spin 3000ms linear infinite;
  -webkit-animation: spin 3000ms linear infinite;
  -moz-animation: spin 3000ms linear infinite;
}

.Avail {
  background: linear-gradient(45deg, #000, #000 100%),
    linear-gradient(135deg, #a5cf6f, #a5cf6f),
    linear-gradient(225deg, #a5cf6f, #a5cf6f),
    linear-gradient(225deg, #000, #000);
  background-size: 50% 50%;
  background-position: 0% 0%, 0% 100%, 100% 0%, 100% 100%;
  background-repeat: no-repeat;
}

.Closed {
  background: linear-gradient(45deg, #000, #000 100%),
    linear-gradient(135deg, #e7040f, #e7040f),
    linear-gradient(225deg, #e7040f, #e7040f),
    linear-gradient(225deg, #000, #000);
  background-size: 50% 50%;
  background-position: 0% 0%, 0% 100%, 100% 0%, 100% 100%;
  background-repeat: no-repeat;
}

/*==============
/* * Animations
====================================*/
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(359deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@media (min-width: 992px) {
  b.mb4-ns {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 30rem) {
  .mb2-ns {
    margin-bottom: 0.8rem;
  }
}
@media (max-width: 796px) {
  .f-headline-ns {
    font-size: 10vmin;
  }
  .mb2-ns {
    margin-bottom: 0.4rem;
  }
}

@media (max-width: 991px) {
  .grid-container--content {
    grid-auto-columns: 0.25fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  b.mb4-ns {
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 769px) {
  b.mb4-ns {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  b.mb4-ns {
    margin-bottom: 0;
  }
}

@media (max-width: 579px) {
  .flex-row-s {
    flex-direction: row;
  }
  b.mb4-ns {
    margin-bottom: 0;
  }
  .text-avail {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
  div.circle {
    width: 1.2rem;
    height: 1.2rem;
  }
  .f-headline-ns {
    font-size: 8vmin;
  }
  .mb2-ns {
    margin-bottom: 0;
  }
}
